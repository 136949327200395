

import { defineComponent, defineAsyncComponent } from 'vue'
import useVuelidate from '@vuelidate/core'
import { required } from '@/customValidators'
import { VehiclesActions } from '@/store/modules/vehicles/actions'
import { OrderActions } from '@/store/modules/orders/actions'
import { ErpNextActions } from '@/store/modules/erp-next/actions'
import { minLength } from '@vuelidate/validators'

export default defineComponent({
  name: 'CreateOrderModal',
  components: {
    BaseIcon: defineAsyncComponent(() => import('@/shared/components/base-icon/BaseIcon.vue')),
    Multiselect: defineAsyncComponent(() => import('@vueform/multiselect')),
    DarkstoreSelect: defineAsyncComponent(() => import('@/views/account/orders/components/DarkstoreSelect.vue')),
    CreateOrderLocationSelectMap: defineAsyncComponent(() => import('@/views/account/orders/components/CreateOrderLocationSelectMap.vue'))
  },
  data () {
    return {
      form: {
        order_id: '',
        order_total: undefined,
        destination_latitude: undefined,
        destination_longitude: undefined,
        customer_phone_number: '',
        vehicle_type_id: '',
        dark_store: undefined as any,
        notes: '',
        erpnext_merchant: '',
        user_address: '',
        address_building: '',
        address_district: '',
        address_flat: '',
        address_floor: '',
        address_nearest_landmark: '',
        address_neighbourhood: '',
        address_nickname: '',
        address_phone: '',
        address_address_line1: ''
      }
    }
  },
  validations: function () {
    return {
      form: {
        order_id: { required },
        order_total: { required },
        destination_latitude: { required },
        destination_longitude: { required },
        customer_phone_number: { required },
        vehicle_type_id: { required },
        dark_store: { required },
        user_address: { required, minLength: minLength(5) },
        erpnext_merchant: { required }
      }
    }
  },
  props: {
  },
  computed: {
    vehicleTypes (): any[] {
      return this.$store.getters['vehicles/getVehicleTypes']
        .map((el: any) => {
          return {
            id: el.id,
            label: el.value
          }
        })
    },
    merchants (): any[] {
      return this.$store.getters['erpNext/getMerchants']
    }
  },
  setup (_, { emit }) {
    const closeModal = ($event: any, out = true) => {
      if (out && !$event.inner) {
        emit('close', false)
      } else $event.inner = true
    }
    return {
      closeModal,
      v$: useVuelidate()
    }
  },
  methods: {
    async submitForm (validator: any): Promise<any> {
      validator.$touch()

      if (validator.$invalid) return

      if (!validator.$invalid) {
        this.$store.dispatch(`orders/${[OrderActions.CREATE_GENERAL_ORDER]}`, {
          ...this.form,
          darkstore_id: this.form.dark_store?.id ?? '',
          darkstore: undefined
        }).then(() => {
          this.$emit('onOrderCreated')
        })
      }
    },
    onLocationSelected (data: { lat: number, lng: number }) {
      this.form.destination_latitude = data.lat as any
      this.form.destination_longitude = data.lng as any
    }
  },
  mounted () {
    if (this.vehicleTypes.length === 0) {
      this.$store.dispatch(`vehicles/${[VehiclesActions.FETCH_VEHICLE_TYPES]}`)
    }
    this.$store.dispatch(`erpNext/${[ErpNextActions.FETCH_ERP_NEXT_MERCHANTS]}`)
  }
})
