import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "relative mt-4" }
const _hoisted_2 = { class: "bg-white rounded mt-2 px-6 pb-6 pt-4 shadow-mainShadow text-start overflow-hidden w-768 relative" }
const _hoisted_3 = { class: "flex justify-between items-center border-b pb-3" }
const _hoisted_4 = { class: "text-black font-bold text-lg" }
const _hoisted_5 = { class: "flex gap-4" }
const _hoisted_6 = { class: "w-1/2 flex flex-col gap-5" }
const _hoisted_7 = { class: "flex flex-col gap-4" }
const _hoisted_8 = { class: "relative" }
const _hoisted_9 = {
  key: 0,
  class: "form-error"
}
const _hoisted_10 = { class: "relative" }
const _hoisted_11 = {
  key: 0,
  class: "form-error"
}
const _hoisted_12 = { class: "w-1/2 flex flex-col gap-5" }
const _hoisted_13 = { class: "flex gap-5" }
const _hoisted_14 = { class: "relative w-full" }
const _hoisted_15 = {
  key: 0,
  class: "form-error"
}
const _hoisted_16 = { class: "relative w-full" }
const _hoisted_17 = {
  key: 0,
  class: "form-error"
}
const _hoisted_18 = { class: "flex gap-4" }
const _hoisted_19 = { class: "relative w-full" }
const _hoisted_20 = {
  key: 0,
  class: "form-error"
}
const _hoisted_21 = { class: "relative w-full" }
const _hoisted_22 = {
  key: 0,
  class: "form-error"
}
const _hoisted_23 = { class: "flex gap-4" }
const _hoisted_24 = { class: "relative w-full" }
const _hoisted_25 = {
  key: 0,
  class: "form-error"
}
const _hoisted_26 = { class: "relative w-full" }
const _hoisted_27 = { class: "text-xs mb-1" }
const _hoisted_28 = { class: "relative w-full" }
const _hoisted_29 = {
  key: 0,
  class: "form-error"
}
const _hoisted_30 = { class: "font-bold text-darkText" }
const _hoisted_31 = { class: "flex gap-4" }
const _hoisted_32 = { class: "flex-1" }
const _hoisted_33 = { class: "relative w-full mt-1" }
const _hoisted_34 = {
  key: 0,
  class: "form-error"
}
const _hoisted_35 = { class: "flex-1" }
const _hoisted_36 = { class: "relative w-full mt-1" }
const _hoisted_37 = { class: "text-xs mb-1" }
const _hoisted_38 = { class: "grid grid-cols-4 gap-4 mt-4" }
const _hoisted_39 = { class: "relative w-full" }
const _hoisted_40 = { class: "text-xs mb-1" }
const _hoisted_41 = { class: "relative w-full" }
const _hoisted_42 = { class: "text-xs mb-1" }
const _hoisted_43 = { class: "relative w-full" }
const _hoisted_44 = { class: "text-xs mb-1" }
const _hoisted_45 = { class: "relative w-full" }
const _hoisted_46 = { class: "text-xs mb-1" }
const _hoisted_47 = { class: "relative w-full" }
const _hoisted_48 = { class: "text-xs mb-1" }
const _hoisted_49 = { class: "relative w-full" }
const _hoisted_50 = { class: "text-xs mb-1" }
const _hoisted_51 = { class: "relative w-full" }
const _hoisted_52 = { class: "text-xs mb-1" }
const _hoisted_53 = { class: "relative w-full" }
const _hoisted_54 = { class: "text-xs mb-1" }
const _hoisted_55 = { class: "flex justify-end items-center gap-x-6 mt-4" }
const _hoisted_56 = {
  type: "submit",
  class: "primary py-3 px-6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_CreateOrderLocationSelectMap = _resolveComponent("CreateOrderLocationSelectMap")!
  const _component_DarkstoreSelect = _resolveComponent("DarkstoreSelect")!
  const _component_Multiselect = _resolveComponent("Multiselect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$t('orderCreateModal.title')), 1),
        _createElementVNode("div", {
          role: "button",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
        }, [
          _createVNode(_component_BaseIcon, { name: "close" })
        ])
      ]),
      _createElementVNode("form", {
        onSubmit: _cache[27] || (_cache[27] = _withModifiers(($event: any) => (_ctx.submitForm(_ctx.v$)), ["prevent"])),
        class: "w-full mt-3"
      }, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_CreateOrderLocationSelectMap, {
              style: {"width":"100%","height":"305px"},
              onOnLocationSelect: _ctx.onLocationSelected
            }, null, 8, ["onOnLocationSelect"]),
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_7, [
                _withDirectives(_createElementVNode("div", _hoisted_8, [
                  _createElementVNode("label", {
                    class: _normalizeClass([{error: _ctx.v$.form.destination_latitude.$error}, "text-xs mb-1"])
                  }, _toDisplayString(_ctx.$t('Destination latitude')), 3),
                  _withDirectives(_createElementVNode("input", {
                    class: _normalizeClass([{error: _ctx.v$.form.destination_latitude.$error}, "h-42 px-3"]),
                    type: "text",
                    placeholder: " ",
                    disabled: "",
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.destination_latitude) = $event)),
                    onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.v$.form.destination_latitude.$touch && _ctx.v$.form.destination_latitude.$touch(...args)))
                  }, null, 34), [
                    [_vModelText, _ctx.form.destination_latitude]
                  ]),
                  (_ctx.v$.form.destination_latitude.$error)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.$t('validation.required')), 1))
                    : _createCommentVNode("", true)
                ], 512), [
                  [_vShow, false]
                ]),
                _withDirectives(_createElementVNode("div", _hoisted_10, [
                  _createElementVNode("label", {
                    class: _normalizeClass([{error: _ctx.v$.form.destination_longitude.$error}, "text-xs mb-1"])
                  }, _toDisplayString(_ctx.$t('Destination longitude')), 3),
                  _withDirectives(_createElementVNode("input", {
                    class: _normalizeClass([{error: _ctx.v$.form.destination_longitude.$error}, "h-42 px-3"]),
                    type: "text",
                    placeholder: " ",
                    disabled: "",
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.destination_longitude) = $event)),
                    onBlur: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.v$.form.destination_longitude.$touch && _ctx.v$.form.destination_longitude.$touch(...args)))
                  }, null, 34), [
                    [_vModelText, _ctx.form.destination_longitude]
                  ]),
                  (_ctx.v$.form.destination_longitude.$error)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.$t('validation.required')), 1))
                    : _createCommentVNode("", true)
                ], 512), [
                  [_vShow, false]
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("label", {
                  class: _normalizeClass([{error: _ctx.v$.form.order_id.$error}, "text-xs mb-1"])
                }, _toDisplayString(_ctx.$t('orderCreateModal.orderId')), 3),
                _withDirectives(_createElementVNode("input", {
                  class: _normalizeClass([{error: _ctx.v$.form.order_id.$error}, "h-42 px-3 w-full"]),
                  type: "text",
                  placeholder: "",
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.order_id) = $event)),
                  onBlur: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.v$.form.order_id.$touch && _ctx.v$.form.order_id.$touch(...args)))
                }, null, 34), [
                  [_vModelText, _ctx.form.order_id]
                ]),
                (_ctx.v$.form.order_id.$error)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(_ctx.$t('validation.required')), 1))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("label", {
                  class: _normalizeClass([{error: _ctx.v$.form.order_total.$error}, "text-xs mb-1"])
                }, _toDisplayString(_ctx.$t('orderCreateModal.total')), 3),
                _withDirectives(_createElementVNode("input", {
                  class: _normalizeClass([{error: _ctx.v$.form.order_total.$error}, "h-42 px-3 w-full"]),
                  type: "number",
                  placeholder: " ",
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.form.order_total) = $event)),
                  onBlur: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.v$.form.order_total.$touch && _ctx.v$.form.order_total.$touch(...args)))
                }, null, 34), [
                  [_vModelText, _ctx.form.order_total]
                ]),
                (_ctx.v$.form.order_total.$error)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(_ctx.$t('validation.required')), 1))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("div", _hoisted_19, [
                _createElementVNode("label", {
                  class: _normalizeClass([{error: _ctx.v$.form.dark_store.$error}, "text-xs mb-1"])
                }, _toDisplayString(_ctx.$t('orderCreateModal.darkstore')), 3),
                _createVNode(_component_DarkstoreSelect, {
                  modelValue: _ctx.form.dark_store,
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.form.dark_store) = $event)),
                  class: _normalizeClass({error: _ctx.v$.form.dark_store.$error})
                }, null, 8, ["modelValue", "class"]),
                (_ctx.v$.form.dark_store.$error)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_20, _toDisplayString(_ctx.$t('validation.required')), 1))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_21, [
                _createElementVNode("label", {
                  class: _normalizeClass([{error: _ctx.v$.form.vehicle_type_id.$error}, "text-xs mb-1"])
                }, _toDisplayString(_ctx.$t('orderCreateModal.vehicle')), 3),
                _createVNode(_component_Multiselect, {
                  class: _normalizeClass(["w-full", {'error' : _ctx.v$.form.vehicle_type_id.$error}]),
                  canDeselect: false,
                  modelValue: _ctx.form.vehicle_type_id,
                  "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.form.vehicle_type_id) = $event)),
                  options: _ctx.vehicleTypes,
                  canClear: false,
                  label: "label",
                  valueProp: "id",
                  noOptionsText: _ctx.$t('validation.noItemsToShow')
                }, null, 8, ["modelValue", "options", "noOptionsText", "class"]),
                (_ctx.v$.form.vehicle_type_id.$error)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_22, _toDisplayString(_ctx.$t('validation.required')), 1))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("div", _hoisted_23, [
              _createElementVNode("div", _hoisted_24, [
                _createElementVNode("label", {
                  class: _normalizeClass([{error: _ctx.v$.form.customer_phone_number.$error}, "text-xs mb-1"])
                }, _toDisplayString(_ctx.$t('orderCreateModal.phone')), 3),
                _withDirectives(_createElementVNode("input", {
                  class: _normalizeClass([{error: _ctx.v$.form.customer_phone_number.$error}, "h-42 px-3 w-full"]),
                  type: "text",
                  placeholder: " ",
                  "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.form.customer_phone_number) = $event)),
                  onBlur: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.v$.form.customer_phone_number.$touch && _ctx.v$.form.customer_phone_number.$touch(...args)))
                }, null, 34), [
                  [_vModelText, _ctx.form.customer_phone_number]
                ]),
                (_ctx.v$.form.customer_phone_number.$error)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_25, _toDisplayString(_ctx.$t('validation.required')), 1))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_26, [
                _createElementVNode("label", _hoisted_27, _toDisplayString(_ctx.$t('orderCreateModal.notes')), 1),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  placeholder: "",
                  class: "h-42 px-3 w-full",
                  "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.form.notes) = $event))
                }, null, 512), [
                  [_vModelText, _ctx.form.notes]
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_28, [
              _createElementVNode("label", {
                class: _normalizeClass([{error: _ctx.v$.form.erpnext_merchant.$error}, "text-xs mb-1"])
              }, _toDisplayString(_ctx.$t('orderCreateModal.erpnextMerchant')), 3),
              _createVNode(_component_Multiselect, {
                class: _normalizeClass(["w-full", {'error' : _ctx.v$.form.erpnext_merchant.$error}]),
                canDeselect: false,
                modelValue: _ctx.form.erpnext_merchant,
                "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.form.erpnext_merchant) = $event)),
                options: _ctx.merchants,
                canClear: false,
                noOptionsText: _ctx.$t('validation.noItemsToShow')
              }, null, 8, ["modelValue", "options", "noOptionsText", "class"]),
              (_ctx.v$.form.erpnext_merchant.$error)
                ? (_openBlock(), _createElementBlock("span", _hoisted_29, _toDisplayString(_ctx.$t('validation.required')), 1))
                : _createCommentVNode("", true)
            ])
          ])
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("span", _hoisted_30, _toDisplayString(_ctx.$t('drivers.address')), 1),
          _createElementVNode("div", _hoisted_31, [
            _createElementVNode("div", _hoisted_32, [
              _createElementVNode("div", _hoisted_33, [
                _createElementVNode("label", {
                  class: _normalizeClass([{error: _ctx.v$.form.user_address.$error}, "text-xs mb-1"])
                }, _toDisplayString(_ctx.$t('filters.userAddress')), 3),
                _withDirectives(_createElementVNode("input", {
                  class: _normalizeClass([{error: _ctx.v$.form.user_address.$error}, "h-42 px-3 w-full"]),
                  type: "text",
                  placeholder: "",
                  "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.form.user_address) = $event)),
                  onBlur: _cache[16] || (_cache[16] = 
//@ts-ignore
(...args) => (_ctx.v$.form.user_address.$touch && _ctx.v$.form.user_address.$touch(...args)))
                }, null, 34), [
                  [_vModelText, _ctx.form.user_address]
                ]),
                (_ctx.v$.form.user_address.$error)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_34, _toDisplayString(_ctx.form.user_address ? _ctx.$t('validation.min_value', { min: 5 }) :  _ctx.$t('validation.required')), 1))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("div", _hoisted_35, [
              _createElementVNode("div", _hoisted_36, [
                _createElementVNode("label", _hoisted_37, _toDisplayString(_ctx.$t('drivers.addressLine1')), 1),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  placeholder: "",
                  class: "h-42 px-3 w-full",
                  "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.form.address_address_line1) = $event))
                }, null, 512), [
                  [_vModelText, _ctx.form.address_address_line1]
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_38, [
            _createElementVNode("div", _hoisted_39, [
              _createElementVNode("label", _hoisted_40, _toDisplayString(_ctx.$t('drivers.nick')), 1),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                placeholder: "",
                class: "h-42 px-3 w-full",
                "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.form.address_nickname) = $event))
              }, null, 512), [
                [_vModelText, _ctx.form.address_nickname]
              ])
            ]),
            _createElementVNode("div", _hoisted_41, [
              _createElementVNode("label", _hoisted_42, _toDisplayString(_ctx.$t('drivers.district')), 1),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                placeholder: "",
                class: "h-42 px-3 w-full",
                "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.form.address_district) = $event))
              }, null, 512), [
                [_vModelText, _ctx.form.address_district]
              ])
            ]),
            _createElementVNode("div", _hoisted_43, [
              _createElementVNode("label", _hoisted_44, _toDisplayString(_ctx.$t('drivers.neighbourhood')), 1),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                placeholder: "",
                class: "h-42 px-3 w-full",
                "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.form.address_neighbourhood) = $event))
              }, null, 512), [
                [_vModelText, _ctx.form.address_neighbourhood]
              ])
            ]),
            _createElementVNode("div", _hoisted_45, [
              _createElementVNode("label", _hoisted_46, _toDisplayString(_ctx.$t('drivers.address_phone')), 1),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                placeholder: "",
                class: "h-42 px-3 w-full",
                "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.form.address_phone) = $event))
              }, null, 512), [
                [_vModelText, _ctx.form.address_phone]
              ])
            ]),
            _createElementVNode("div", _hoisted_47, [
              _createElementVNode("label", _hoisted_48, _toDisplayString(_ctx.$t('drivers.building')), 1),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                placeholder: "",
                class: "h-42 px-3 w-full",
                "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.form.address_building) = $event))
              }, null, 512), [
                [_vModelText, _ctx.form.address_building]
              ])
            ]),
            _createElementVNode("div", _hoisted_49, [
              _createElementVNode("label", _hoisted_50, _toDisplayString(_ctx.$t('drivers.flat')), 1),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                placeholder: "",
                class: "h-42 px-3 w-full",
                "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.form.address_flat) = $event))
              }, null, 512), [
                [_vModelText, _ctx.form.address_flat]
              ])
            ]),
            _createElementVNode("div", _hoisted_51, [
              _createElementVNode("label", _hoisted_52, _toDisplayString(_ctx.$t('drivers.floor')), 1),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                placeholder: "",
                class: "h-42 px-3 w-full",
                "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.form.address_floor) = $event))
              }, null, 512), [
                [_vModelText, _ctx.form.address_floor]
              ])
            ]),
            _createElementVNode("div", _hoisted_53, [
              _createElementVNode("label", _hoisted_54, _toDisplayString(_ctx.$t('drivers.landmark')), 1),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                placeholder: "",
                class: "h-42 px-3 w-full",
                "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.form.address_nearest_landmark) = $event))
              }, null, 512), [
                [_vModelText, _ctx.form.address_nearest_landmark]
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_55, [
          _createElementVNode("button", {
            type: "button",
            onClick: _cache[26] || (_cache[26] = ($event: any) => (_ctx.$emit('close'))),
            class: "danger-btn py-3 px-6"
          }, _toDisplayString(_ctx.$t('buttons.close')), 1),
          _createElementVNode("button", _hoisted_56, _toDisplayString(_ctx.$t('create')), 1)
        ])
      ], 32)
    ])
  ]))
}